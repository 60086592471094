import React from "react";
import { useTranslation } from "react-i18next";
import bostonliq from "../../assets/images/bostonliq.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#1eb2a6] relative text-white">
      <div className="flex justify-center items-center px-[100px] gap-8 h-[400px]">
        <div>
          <img src={bostonliq} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[20px] mt-[-50px] text-[36px] text-#121221">
            {t("Bo'stonliq tumani haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Boʻstonliq tumani</strong>— Toshkent
            viloyati tarkibidagi tuman.1955-yil 19-aprelda tashkil etilgan
            (1962-yil 24-dekabr da Yuqori Chirchiq tumaniga qoʻshib yuborilgan,
            1968-yil 25-dekabr da qayta tuzilgan). Boʻstonliq tumanda tumanga
            boʻysunuvchi 1 shahar (Gʻazalkent), 18 qishloq fuqarolar yigʻini
            (Bogʻiston, Boʻstonliq, Dumaloq, Jahonobod, Ozodbosh, Pargos,
            Sijjak, Soyliq, Toshpoʻlat Dadaboyev, Ugom, Xumson, Chimboyliq,
            Chimyon, Yangiovul, Qoramanas, Qoʻshqoʻrgʻon, Gʻalaba, Hondayliq)
            bor. Markazi — Gʻazalkent shahri
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
