import React from "react";
import { useGetStatistika } from "../../hooks/query";
import { Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { BiMessageDetail } from "react-icons/bi";
import { WiTime8 } from "react-icons/wi";
import {TiInputChecked} from "react-icons/ti"
import {HiOutlineTableCells} from "react-icons/hi2"
import {MdOutlineReportGmailerrorred} from "react-icons/md"

const Statistika = () => {
  const useGetStatistikaList = useGetStatistika();
  const { t } = useTranslation();
  console.log(useGetStatistikaList);
  if (useGetStatistikaList.isLoading) {
    return (
      <div className="flex justify-center h-[570px]">
        <Loader color="#014f86" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div className="h-[400px]">
      <h3 className="font-[700] text-center lg:text-[30px] text-[22px] text-#121221 mb-[60px]">
        {t("Murojaatlar statistikasi")}
      </h3>

      {useGetStatistikaList.data?.map((item) => (
        <div
          key={item.id}
          className="flex justify-evenly text-center text-[#1eb2a6]"
        >
          <div className="flex flex-col items-center shadow-md p-8 w-[220px] lg:h-[250px] gap-2 hover:shadow-2xl duration-700">
            <BiMessageDetail size={60} />
            <p className="font-[600] text-[36px] ">{item.appeals}</p>
            <p className="text-[18px] font-[500]">{t("Umumiy murojaatlar")}</p>
          </div>
          <div className="flex flex-col items-center shadow-md p-8 w-[220px] h-[250px] gap-3 hover:shadow-2xl duration-700">
            <WiTime8 size={60} />
            <p className="font-[600] text-[36px] ">{item.looking_appeals}</p>
            <p className="text-[18px] font-[500]">{t("Jarayonda")}</p>
          </div>
          <div className="flex flex-col items-center shadow-md p-8 w-[220px] h-[250px] gap-3 hover:shadow-2xl duration-700">
            <TiInputChecked size={60} />
            <p className="font-[600] text-[36px] ">{item.expertiza}</p>
            <p className="text-[18px] font-[500]">{t("Qanoatlantirildi")}</p>
          </div>
          <div className="flex flex-col items-center shadow-md p-8 w-[220px] h-[250px] gap-3 hover:shadow-2xl duration-700">
            <HiOutlineTableCells size={60} />
            <p className="font-[600] text-[36px] ">{item.understand}</p>
            <p className="text-[18px] font-[500]">{t("Tushuntirildi")}</p>
          </div>
          <div className="flex flex-col items-center shadow-md p-8 w-[220px] h-[250px] gap-3 hover:shadow-2xl duration-700">
            <MdOutlineReportGmailerrorred size={60} />
            <p className="font-[600] text-[36px] ">{item.canceled}</p>
            <p className="text-[18px] font-[500]">{t("Rad etildi")}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Statistika;
